import type { JSX } from 'solid-js';

export type Props = JSX.IntrinsicElements['svg'];

export default function Logo(props: Props) {
  return (
    <div class="relative">
      <span class="absolute -right-4 top-0 inline-flex scale-75 items-center rounded-full bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">
        beta
      </span>
      <svg viewBox="0 0 1000 563" {...props}>
        <path
          fill="currentColor"
          d="M113.03 321.34c25.53 0 40.16 13.08 41.4 14.32v-9.96h26.15v55.41h-26.15c-3.11-19.61-16.19-33.31-38.91-33.31-26.15 0-43.58 20.24-43.58 49.81 0 31.13 18.06 52.92 44.83 52.92 12.45 0 33-4.67 44.21-28.95l23.97 11.21C172.19 461.74 147.9 477 115.84 477c-52.3 0-75.03-43.58-75.03-79.07-.01-42.35 32.06-76.59 72.22-76.59zM251.25 261.26v81.25c21.79-17.74 37.05-21.17 48.88-21.17 26.15 0 40.47 15.25 40.47 43.27v82.5h25.22v25.53h-54.48V371.15c0-15.57-5.92-23.97-18.06-23.97-10.9 0-25.84 7.16-42.03 19.3v80.63h25.22v25.53h-81.25v-25.53h26.77V286.78h-26.46v-25.53h55.72zM416.24 325.7v103.36c0 13.39 5.92 20.55 17.43 20.55 12.14 0 28.95-7.16 42.03-18.68v-79.7h-25.22V325.7h54.48v121.41h25.22v25.53H475.7v-16.81c-17.74 14.01-33.62 19.3-47.94 19.3-26.77 0-40.78-15.25-40.78-44.21v-79.7h-20.86V325.7h50.12z"
        />
        <path
          fill="currentColor"
          d="M643.19 323.21v29.26h-16.81c-30.2 0-42.96 16.81-42.96 51.06v43.58h31.75v25.53H525.2v-25.53h28.95v-95.88h-29.57V325.7h52.61v33h.62c10.9-27.71 31.75-35.49 45.45-35.49h19.93zM715.1 321.34c25.53 0 40.16 13.08 41.4 14.32v-9.96h26.15v55.41H756.5c-3.11-19.61-16.19-33.31-38.91-33.31-26.15 0-43.58 20.24-43.58 49.81 0 31.13 18.06 52.92 44.83 52.92 12.45 0 33-4.67 44.21-28.95l23.97 11.21C774.26 461.74 749.97 477 717.91 477c-52.3 0-75.03-43.58-75.03-79.07 0-42.35 32.06-76.59 72.22-76.59zM847.1 261.26v81.25c21.79-17.74 37.05-21.17 48.88-21.17 26.15 0 40.47 15.25 40.47 43.27v82.5h25.22v25.53h-54.48V371.15c0-15.57-5.92-23.97-18.06-23.97-10.9 0-25.84 7.16-42.03 19.3v80.63h25.22v25.53h-81.25v-25.53h26.77V286.78h-26.46v-25.53h55.72zM336.81 73.9v185.85h22.73v25.53h-78.76v-25.53h26.77V99.43H281.4V73.9h55.41zM443.27 133.98c40.78 0 78.45 31.13 78.45 84.99H398.13c4.05 25.53 19.61 45.14 50.43 45.14 18.68 0 36.11-8.09 47.63-23.97l25.53 11.21c-15.57 24.28-42.03 38.29-73.78 38.29-47.63 0-81.87-32.38-81.87-77.83 0-47.63 39.22-77.83 77.2-77.83zm-44.2 63.82h93.08c-4.36-22.1-23.97-40.47-48.56-40.47-23.67 0-40.17 16.5-44.52 40.47zM575.27 89.16v49.19h27.08v25.53h-27.08v85.61c0 7.16 4.05 11.21 11.83 11.21 4.05 0 8.41-.93 15.25-3.11v26.15c-10.27 3.11-17.12 4.05-21.48 4.05-22.73 0-34.87-11.83-34.87-33.93v-89.97h-18.99v-25.53H546v-22.73l29.27-26.47zM654.03 73.9v38.6c0 29.57-13.7 40.47-37.36 40.78v-15.57c15.57-3.42 18.68-12.14 18.68-26.46h-18.68V73.9h37.36zM695.43 135.85c12.14 0 24.9 2.8 35.8 8.41v-5.92h25.53v48.56h-25.53v-9.03c-8.09-12.14-18.99-18.06-34.24-18.06-15.88 0-25.84 7.47-25.84 18.06 0 12.45 13.7 14.94 33.62 17.74 17.12 2.49 37.05 5.6 48.88 18.37 6.54 6.85 10.9 18.37 10.9 28.95 0 26.46-22.41 44.83-56.35 44.83-25.53 0-38.29-10.27-38.6-10.58v8.09h-25.84v-50.43h25.84v4.98c2.49 14.94 16.19 23.66 35.18 23.66 18.06 0 30.2-6.85 30.2-18.37 0-14.01-13.08-16.19-33.31-19.3-15.57-2.49-29.89-5.92-41.09-14.01-9.96-7.47-16.81-18.99-16.81-32.69-.02-24.89 21.46-43.26 51.66-43.26z"
        />
      </svg>
    </div>
  );
}
